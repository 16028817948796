<template>
    <AppHeader />
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="sign__content">
                        <form action="http://sudanipay.com/subscription/nashoof-sd/nashoof-sd" class="sign__form" style="max-width: 600px;" method="POST">
                            <a href="http://www.sudani.sd" class="sign__logo">
                                <img src="assets/img/SudaniWhiteLogo-NoShadow.png" alt="" />
                             </a>
                            <!-- <template v-for="data in Token" :key="data.id">
							              <input type="hidden" name="serviceCode" v-model="data.serviceCode"/>
              						  <input type="hidden" name="token" v-model="data.token" />  -->
                          	<!-- <input type="hidden" name="MSISDN" v-model="MSISDN" /> -->
                            <!-- </template> -->
                            <p style="text-align: center;font-family: 'Cairo', sans-serif;font-size: 18px;">نشوف .. منصة الكترونية مدفوعة القيمة للعروض الفنية الحصرية ونافذة للمحتوي الترفيهي بمعايير عالمية ودقة عالية تجد من خلالها احدث الاعمال الفنية لنجومك المفضلين</p>
							              <div class="sign__group" style="margin-bottom: 2px;">
                              <a href="http://sudanipay.com/subscription/nashoof-sd/nashoof-sd" class="sign__btn" style="margin: 30px 0 0 auto;" >إشتــرك الأن</a>
								            </div>
                            <div class="sign__group" style="margin: 0px 0 0 auto;">
								            </div>
                            <p style='text-align: justify; font-family: "Cairo", sans-serif;margin-top: 16px;'>  * قيمة الاشتراك اليومي 50 جنيه شاملة الضريبة</p>
                            <p style="text-align: justify;font-family: 'Cairo', sans-serif;">  * سوف يتم تجديد الاشتراك تلقائيا</p>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    <AppFooter/>
</template>

<script>
import { HTTP } from '@/Api/http-common';
import AppHeader from "../Include/AppHeader.vue";
import AppFooter from "../Include/AppFooter.vue";
import { ref } from '@vue/reactivity';
export default {
  components: { AppFooter,AppHeader},
  name: 'Landing',
    mounted() {
       if (this.$cookie.isCookieAvailable("msisdn") && this.$cookie.isCookieAvailable("status")) {
          this.$router.push({ path: "/" })
        }
    },
    setup() {
        const Token = ref([]);
        try {
             HTTP.get(`GetTokenData.php`).then((res) => {
                Token.value = res.data.TokenData; 
            });
        } catch (err) {
            console.log(err);
        }
      return { Token };
    },
  }
</script>

<style scoped>

</style>